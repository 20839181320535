<!-- 优势资源 -->
<template>
  <div class="move-frame">
    <div class="body">
      <top showTitle="走进黑水" :childNavs="navs"></top>
      <div class="content">
        <div class="left">
          <!-- 一级 -->
          <div class="type_item active">
            <div class="show">
              <div class="label">资源</div>
              <i class="plus el-icon-plus"></i>
              <i class="minus el-icon-minus"></i>
            </div>
            <!-- 二级 -->
            <div class="sub_list">
              <div
                v-for="item in list"
                :key="item.name"
                :class="{
                  sub_item: true,
                  sub_active: activeSubType == item.name,
                }"
                @click="changeType(item.name)"
              >
                <div class="sub_title">
                  <img :src="item.icon" alt="" class="icon" />
                  <img :src="item.activeIcon" alt="" class="active_icon" />
                  <div class="text">{{ item.name }}</div>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="center_title">
            <i class="arrow el-icon-caret-right"></i>
            <div class="center_label">{{ activeSubType }}</div>
          </div>
          <div id="container"></div>
        </div>
        <div class="right">
          <div class="choose">
            <div class="choice active">{{ activeSubType }}资源</div>
          </div>
          <div class="desc" v-html="nowData.introduceStr"></div>
        </div>
      </div>
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import Bottom from "../../components/bottom.vue";
import top from "../../components/top.vue";
import routes from "../../router/moveWater";

import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  components: { top, Bottom },
  data() {
    return {
      navs: routes,
      map: null,
      AMap: null,
      activeType: "", // 第一级激活类
      activeSubType: "", // 第二级激活类
      activeLastType: "", // 第三级激活类
      list: [
        {
          name: "电力",
          icon: require("../../assets/move/electric.png"),
          activeIcon: require("../../assets/move/electric_active.png"),
        },
        {
          name: "旅游",
          icon: require("../../assets/move/travel.png"),
          activeIcon: require("../../assets/move/travel_active.png"),
        },
        {
          name: "农产品加工",
          icon: require("../../assets/move/process.png"),
          activeIcon: require("../../assets/move/process_active.png"),
        },
        {
          name: "中药材",
          icon: require("../../assets/move/herb.png"),
          activeIcon: require("../../assets/move/herb_active.png"),
        },
      ],
      nowData: {},
    };
  },
  created() {
    this.changeType(this.list[0].name);
  },
  computed: {
    formatDesc() {
      return (value) => {
        if (!value) return;
        return value.replace(/\n/g, "<br>");
      };
    },
  },
  mounted() {},
  methods: {
    async changeType(name) {
      this.activeSubType = name;
      const params = {
        pageIndex: 1,
        pageSize: 1,
        resourceName: name,
      };
      let res = await this.$api.getResource(params);
      this.nowData = res.data.list[0] || [];
      this.map ? this.initCover() : this.initMap();
    },
    initMap() {
      AMapLoader.load({
        key: this.$mapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("container", {
            //设置地图容器id
            zoom: 14, //初始化地图级别
            center: [102.987592, 32.069268], //初始化地图中心点位置
          });
          this.map.addControl(new AMap.Scale());
          this.initCover();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    initCover() {
      this.map.clearMap();
      let allPathes = [];
      const lnglat = this.nowData.longituAndLatitu;
      allPathes = lnglat ? JSON.parse(lnglat) : [];
      allPathes.forEach((ele) => {
        let marker = new this.AMap.CircleMarker({
          center: ele,
          radius: 10, //3D视图下，CircleMarker半径不要超过64px
          strokeColor: "white",
          strokeWeight: 2,
          strokeOpacity: 0.5,
          fillColor: "rgba(0,0,255,1)",
          fillOpacity: 0.5,
          zIndex: 10,
          bubble: true,
          cursor: "pointer",
          clickable: true,
        });
        marker.setMap(this.map);
      });
      this.map.setFitView();
    },
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  },
};
</script>

<style lang='scss' scoped>
.content {
  width: 100%;
  background-color: rgb(250 251 253 / 70%);
  position: relative;
  box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);
  padding: 34px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .left {
    width: 300px;
    margin-right: 20px;

    .type_item {
      height: 100%;
      background-color: #fff;
      padding: 10px;
      margin-bottom: 10px;
      color: #cfcfcf;
      &:hover {
        color: $theme;
        .show {
          .label {
            color: $theme;
          }
          &::before {
            background-color: $theme;
          }
        }
      }

      .show {
        font-weight: bold;
        padding: 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &::before {
          content: "";
          width: 3px;
          height: 60%;
          border-radius: 10px;
          background-color: #cfcfcf;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .label {
          padding-left: 10px;
          color: #333;
          font-size: 18px;
        }
        .minus {
          display: none;
        }
      }

      .sub_list {
        width: 100%;
        height: calc(100% - 30px);
        padding: 7px 0;
        box-sizing: border-box;
        margin-top: 7px;
        background-color: #f0f5fa;
        color: #333;
        max-height: 0;
        transition: maxHeight 0.5s;
        overflow: hidden;

        .sub_item {
          cursor: pointer;
          padding: 0 20px;

          &:hover {
            color: #fff;
            background-color: $theme;

            .sub_title {
              border-color: transparent;
              .icon {
                display: none;
              }
              .active_icon {
                display: block;
              }
            }
          }
          .sub_title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid #cfcfcf;
            img {
              width: 20px;
            }
            .active_icon {
              display: none;
            }
            .text {
              flex-grow: 1;
              margin-left: 20px;
            }
            .up {
              display: none;
            }
            i {
              font-size: 14px;
            }
          }
        }
        .sub_active {
          color: #fff;
          background-color: $theme;

          .sub_title {
            border-color: transparent;

            .icon {
              display: none;
            }
            .active_icon {
              display: block;
            }
          }
        }
      }
    }

    .active {
      color: $theme;

      .sub_list {
        max-height: 1000px;
      }
      .show {
        &::before {
          background-color: $theme;
        }
        .label {
          color: $theme;
        }
        .plus {
          display: none;
        }
        .minus {
          display: block;
        }
      }
    }
  }
  .center {
    flex-grow: 1;
    position: relative;

    #container {
      width: 100%;
      height: 100%;
    }

    &_title {
      width: 95%;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      background-color: rgba(246, 251, 255, 0.7);
      border: 1px solid #fff;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        color: $theme;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      .center_label {
        margin-left: 35px;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .right {
    width: 500px;
    margin-left: 20px;
    background-color: #fff;
    padding: 10px 15px;
    box-sizing: border-box;

    .choose {
      height: 40px;
      border: 1px solid #eee;
      border-radius: 5px;
      display: flex;
      justify-content: space-evenly;
      font-size: 18px;
      line-height: 40px;

      .choice {
        height: 100%;
        cursor: pointer;
        position: relative;

        &:hover {
          color: $theme;

          &::after {
            width: 100%;
          }
        }
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          border-radius: 5px;
          background-color: $theme;
          position: absolute;
          left: 0;
          bottom: -1px;
          transition: width 0.2s;
        }
      }
      .active {
        font-weight: bold;
        color: $theme;

        &::after {
          width: 100%;
        }
      }
    }
    .desc {
      font-size: 14px;
      line-height: 1.5;
      margin-top: 25px;
      padding: 0 5px;
      text-align: justify;
      max-height: calc(100% - 70px);
      overflow: auto;

      img {
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 1700px) {
  .content .right {
    width: 400px;

    .choose {
      font-size: 15px;
    }
    .desc {
      font-size: 13px;
    }
  }
}
@media (max-width: 1500px) {
  .content {
    .left {
      width: 200px;

      .type_item {
        font-size: 14px;
      }
    }
    .right {
      width: 300px;
    }
  }
}
</style>

